import React, { useState } from 'react' 
import "./AddEmployee.css"

import { Autocomplete, TextField } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getUserData, setUserLoggedInData } from '../../../redux/features/UserSlice'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { handleSubmitProfile } from '../../Pages/BuildProfileHandler';
import Back from '../../../Components/Back'
import { CountryCodes } from '../../../data/CountryCodeWithLength'

import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { TextCapaitalized } from '../../../data/utilities';

function AddEmployee({ RefreshContent }) {

    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [firstName, setFirstname] = useState('')
    const [lastName, setLastname] = useState('')

    const [designation, setDesignation] = useState('')

    const [education, setEducation] = useState('')

    const [specialization, setSpecialization] = useState('')

    const [client, setClient] = useState('')
    const [billable, setBillable] = useState('')

    const [email, setEmail] = useState('')
    const [isEmailCheck, setIsEmailCheck] = useState('')

    const [pnCEmployeeId, setPnCEmployeeId] = useState('')

    const [phoneNumber, setPhoneNumber] = useState('')
   

    const [commentAlert, setCommentAlert] = useState(false)

    const [reportingTo, setReportingTo] = useState(null)

    const [location, setLocation] = useState(null)


    const handleFirstName = (e) => {

        const REGEX_FirstName = /^[a-zA-Z ]{0,20}$/

        if (REGEX_FirstName.test(e.target.value)) {
            setFirstname(e.target.value)
        }

        if (e.target.value === '') {
            setFirstname(e.target.value)
        }

    }

    const handleLastName = (e) => {
        const REGEX_LastName = /^[a-zA-Z ]{0,20}$/

        if (REGEX_LastName.test(e.target.value)) {
            setLastname(e.target.value)
        }

        if (e.target.value === '') {
            setLastname(e.target.value)
        }

    }

    const handleEmail = (e) => {
        if (e.target.value === "") {
            setIsEmailCheck(false);
        }
        if (e.target.value.length <= 50) {
            setEmail(e.target.value);
        }
    };

    const handlePnCEmployeeId = (e) => {
        const REGEX_PhoneNumber = /^[0-9]*$/
        if (e.target.value.length <= 5) {
            if (REGEX_PhoneNumber.test(e.target.value)) {
                setPnCEmployeeId(e.target.value)
            }
        }
    }

    const handlePhoneNumber = (e) => {
        const REGEX_PhoneNumber = /^[0-9]*$/
        if (e.target.value.length <= Number.parseInt(CountryCodes.filter(item => Number.parseInt(item.country_code) === userData.countryCode)[0].phoneLength)) {
            if (REGEX_PhoneNumber.test(e.target.value)) {
                setPhoneNumber(e.target.value)
            }
        }
    }

    const handleSubmit = async () => {
        if (
            firstName === '' ||
            lastName === '' ||
            designation === '' ||
            client === '' ||
            pnCEmployeeId === '' ||
            education === '' ||
            specialization === ''
        ) {
            toast.error(Messages.BuildProfile.BuildProfile_Provide_Details, { toastId: "iemployee-toast" });
        } else {
            setCommentAlert(false);
            dispatch(setLoadingScreen(true));
    
            const clientValue = ClientColumn.find(item => item.name === client)?.value;
            const billableValue = BillableColumn.find(item => item.name === billable)?.value;
            const locationValue = LocationColumn.find(item => item.name === location)?.value;
    
            let result = await handleSubmitProfile(
                firstName,
                lastName,
                email,
                pnCEmployeeId,
                phoneNumber,
                designation,
                education,
                specialization,
                userData,
                dispatch,
                clientValue,
                setUserLoggedInData,
                RefreshContent,
                navigate,
                setLoadingScreen,
                reportingTo,
                billableValue,
                locationValue
            );
    
            if (result) {
                alert('User Added');
                // navigate('/');
            } else {
                alert('Error in adding the details');
            }
        }
    }
    


const handleReportingTo = (value) => {

        setReportingTo(value)

    }

    return (
        <>
            <div className='build_profile'>
                <Back title={"/myprofile/employees"} />
                {/* header */}
                <div className='build_profile_header'>
                    <div className='build_profile_header_name'>
                        <span>Add User</span>
                    </div>
                </div>

                <form className='build_profile_container' autoComplete='off'>

                    {/* First Name and Last Name */}
                    <div className='build_profile_span_1'>
                        <div className='build_profile_span_field'>
                            <TextField
                                required
                                size='small'
                                label='First Name'
                                name='firstname'
                                sx={{ width: "95%" }}
                                value={firstName}
                                onChange={handleFirstName}
                            />

                        </div>
                        <div className='build_profile_span_field'>
                            <TextField
                                required
                                size='small'
                                label='Last Name'
                                name='lastname'
                                sx={{ width: "95%" }}
                                value={lastName}
                                onChange={handleLastName}
                            />
                        </div>
                    </div>

                    {/* Email and pnCEmployeeId */}
                    <div className='build_profile_span_1'>
                        <div className='build_profile_span_field_input'>
                            <TextField
                                required
                                size='small'
                                label='Email'
                                value={email}
                                onChange={handleEmail}
                                sx={{ width: "95%" }}
                            />
                            <small className={isEmailCheck ? "build_profile_error_show" : "build_profile_error_hide"}>Email doesn't Match</small>
                        </div>
                        <div className='build_profile_span_field_input'>
                            <TextField
                                required
                                size='small'
                                label='PnC Employee ID'
                                value={pnCEmployeeId}
                                onChange={handlePnCEmployeeId}
                                sx={{ width: "95%" }}
                            />
                        </div>
                    </div>

                    {/* Phone Number and Designation */}
                    <div className='build_profile_span_1'>
                    <div className='build_profile_span_field_input'>
                            <TextField
                                size='small'
                                label='Phone Number'
                                value={phoneNumber}
                                onChange={handlePhoneNumber}
                                sx={{ width: "95%" }}
                            />
                            {/* <small className={isPhoneCheck ? "error_show" : "error_hide"}>Phone Number doesn't Match</small> */}
                        </div>
                        <div className='build_profile_span_field'>
                            <Autocomplete
                                freeSolo
                                onChange={(event, value) => setDesignation(value)}
                                options={initialData.Designations.map((options) => options.Designation)}
                                sx={{ width: "95%" }}
                                value={designation}
                                renderInput={(params) => <TextField {...params} name='designation' label="Designation" required size='small' />}
                            />
                        </div>
                    </div>

                    {/* Client and Educations */}  
                    <div className='build_profile_span_1'>
                        <div className='build_profile_span_field'>
                        <Autocomplete
                                onChange={(name, value) => setClient(value)}
                                options={ClientColumn.map((options) => options.name)}
                                sx={{ width: "95%" }}
                                value={client}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="client"
                                        required
                                        label="Client"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                        <div className='build_profile_span_field'>
                            <Autocomplete
                                freeSolo
                                onChange={(event, value) => setEducation(value)}
                                options={initialData.Educations.map((options) => options.Education)}
                                sx={{ width: "95%" }}
                                value={education}
                                renderInput={(params) => <TextField {...params} required name='education' label="Education" size='small' />}
                            />
                        </div>
                    </div>


                    {/* Specialization and Reporting To */}
                    <div className='build_profile_span_1'>
                        <div className='build_profile_span_field'>
                            <TextField 
                                size='small'
                                required
                                value={specialization}
                                onChange={(e) => {
                                    if (e.target.value.length <= 20) {
                                        const REGEX_Specialization = /^[a-zA-Z ]{0,20}$/
                                        if (REGEX_Specialization.test(e.target.value)) {
                                            setSpecialization(e.target.value)
                                        }
                                    }
                                }}
                                label='Specialization' name='specialization' sx={{ width: "95%" }} />
                        </div>
                        <div className='build_profile_span_field'>
                            <Autocomplete
                                onChange={(event, value) => handleReportingTo(value)}
                                options={initialData.Employees.map((options) => TextCapaitalized(options.FirstName + ' ' + options.LastName))}
                                sx={{ width: "95%", textTransform: "capitalize" }}
                                value={TextCapaitalized(reportingTo)}
                                renderInput={(params) => <TextField {...params} name='reportingTo' sx={{ fontWeight: "bold" }} label="Reporting Manager" size='small' />}
                            />
                        </div>
                    </div>
                    {/* Location and Billable*/}
                    <div className="build_profile_span_1">
                        <div className="build_profile_span_field">
                            <Autocomplete
                                onChange={(name, value) => setLocation(value)}
                                options={LocationColumn.map((options) => options.name)}
                                sx={{ width: "95%" }}
                                value={location}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="location"
                                        required
                                        label="Location"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                        <div className="build_profile_span_field">
                            <Autocomplete
                                onChange={(name, value) => setBillable(value)}
                                options={BillableColumn.map((options) => options.name)}
                                sx={{ width: "95%" }}
                                value={billable}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="Billable"
                                        required
                                        label="Billable"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='build_profile_btn'>
                        <input
                            type={'submit'}
                            value={"Create User"}
                            onClick={handleSubmit}
                        />
                    </div>

                </form>

            </div>
        </>
    )
}

export default AddEmployee;


const LocationColumn = [
    {
        name: "India",
        value: 0,
    },
    {
        name: "USA",
        value: 1,
    },
    {
        name: "Philippines",
        value: 2,
    },
];

const BillableColumn = [
  {
    name: "No",
    value: 0,
  },
  {
    name: "Yes",
    value: 1,
  },
]

const ClientColumn = [
    {
        name:"Genzeon",
        value: 1,
    },

    {
        name:"Zelis",
        value: 2,
    },

    {
        name: "Share Care",
        value: 3,
    },
]