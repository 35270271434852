import React from 'react'
import "./Dashboard.css"
import { useNavigate } from 'react-router-dom'
import MyTimeImage from '../../assets/ProductImages/04-iEmployee-MyTime.svg'
import MyProfileImage from '../../assets/ProductImages/02-iEmployee-MyProfile.svg'
import ProjectAllocationImage from '../../assets/ProductImages/03-iEmployee-ProjectAllocation.svg'
import ProjectCodeGenerationImage from '../../assets/ProductImages/05-iEmployee-ProjectCodeGeneration.svg'
import GenzeonLogo from '../../assets/genzeon-logo-2.png'
import { useSelector } from 'react-redux'
import { getUserData } from '../../redux/features/UserSlice'

import { toast } from 'react-toastify'
import Messages from '../../data/Messages.json'

function Dashboard() {

    const navigate = useNavigate()
    const userData = useSelector(getUserData)

    return (
        <div className='iemployee-dashboard-container'>
            {/* About Us- iemployee */}
            <div className='iemployee-about-us-container'>
                <div className='iemployee-about-us-container-card'>
                    <div className='iemployee-about-us-container-span'>
                        <img src={GenzeonLogo} alt='genzeon' />
                    </div>
                    <div className='iemployee-about-us-container-span'>
                        <span>Dream Big,</span>
                        <span>Deliver Excellence</span>
                    </div>
                </div>
            </div>
            {/* Modules */}
            <div className='iemployee-modules-container'>
                <div className='iemployee-module-card-content'>
                    <div className='iemployee-module-card' onClick={() => navigate('/myprofile/employees')}>
                        <div className='iemployee-module-card-img-container'>
                            <img src={MyProfileImage} alt='my-profile' />
                        </div>
                        <span>My Profile</span>
                    </div>
                    <div className='iemployee-module-card' onClick={() => {
                        if (userData.role === process.env.REACT_APP_ROLE_ADMIN) {
                            navigate('/mytime/reports/home')
                            return false;
                        }
                        if (userData.Id !== null) {
                            if (userData.reportingTo !== null && userData.location !== null) {
                                navigate('/mytime/home')
                            }
                            else {
                                toast.warn(Messages.Weekly_Timesheet.Weekly_Timesheet_Warning_Edit_Profile, { toastId: "iemployee-toast" })
                                navigate('/profile')
                            }
                        }
                        else {
                            toast.warn(Messages.Weekly_Timesheet.Weekly_Timesheet_Warning, { toastId: "iemployee-toast" })
                            navigate('/myprofile/build-profile')
                        }
                    }}>
                        <div className='iemployee-module-card-img-container'>
                            <img src={MyTimeImage} alt='my-time' />
                        </div>
                        <span>My Time</span>
                    </div>
                    {
                        (userData.role === process.env.REACT_APP_ROLE_MANAGER || userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) && (
                            <div className='iemployee-module-card' onClick={() => { navigate('/project-allocation/home') }}>
                                <div className='iemployee-module-card-img-container'>
                                    <img src={ProjectAllocationImage} alt='project-allocations' />
                                </div>
                                <span>Project Allocation</span>
                            </div>
                        )
                    }
                    {
                        (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) && (
                            <div className='iemployee-module-card' onClick={() => { navigate('/project-codes/home') }}>
                                <div className='iemployee-module-card-img-container'>
                                    <img src={ProjectCodeGenerationImage} alt='project-codes' />
                                </div>
                                <span>Project Code Generation</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Dashboard