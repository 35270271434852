import log from 'loglevel';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const CONNECTIONSTRING = process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING;
let appInsights = null;

if (CONNECTIONSTRING) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: CONNECTIONSTRING
    },
  });
  appInsights.loadAppInsights();
} else {
  console.log("Instrumentation Key is not defined");
}

const Logger = () => {
  const logMessage = (message, level = 'info') => {
    log[level](message);
    if (appInsights) {
      appInsights.trackTrace({ message });
    }
  };

  return {
    error: (error) => logMessage(error, 'error'),
    warn: (message) => logMessage(message, 'warn'),
    info: (message) => logMessage(message, 'info'),
    log: (message) => logMessage(message, 'log')
  };
};

export default Logger();
